@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Kalnia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tech-gradient-text {
  background: linear-gradient(to right, #e6c541 0%, #2c49ab 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  
}

.gradient-text {
  background: linear-gradient(to right, #fef9c3 0%, #dbd16e 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  
}

.blue-gradient-text {
  background: linear-gradient(to right, #65a6dc 0%, #093ce1 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block; /* This will apply the background only to the text */
}

.org-gradient-text {
  background: linear-gradient(to right, #cf9957 0%, #ea841e 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.logo-size {
  width: 20px; /* Adjust the size as needed */
  height: auto;
}


.transition-spacing {
  transition: margin 0.3s ease, padding 0.3s ease;
}

.home-page-container {
  scroll-behavior: smooth;
}

.img-grayscale {
  filter: grayscale(100%);
  transition: filter 300ms duration-300 ease-in-out;
}

.img-grayscale:hover {
  filter: grayscale(0);
}

.swiper-image {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
}

